import { Injectable } from "@angular/core";
import { HttpService } from "./http.service";
import { CreatePackageBannerDto, PackageBannerDto, UpdatePackageBannerDto } from "@api/dto";

@Injectable()
export class PackageBannerService {
  constructor(
    private httpService: HttpService) {
  }

  async updateSort(packageBannerIds: string[]) {
    return await this.httpService.put<boolean>(`/admin/package-banner/sort`, { packageBannerIds })
  }

  async findOne(id: string) {
    return await this.httpService.get<PackageBannerDto>(`/admin/package-banner/${id}`)
  }

  async create(id: string, createPackageBannerDto: CreatePackageBannerDto) {
    return await this.httpService.post<PackageBannerDto>(`/admin/package-banner/${id}`, createPackageBannerDto)
  }

  async update(id: string, updatePackageBannerDto: UpdatePackageBannerDto) {
    return await this.httpService.put<boolean>(`/admin/package-banner/${id}`, updatePackageBannerDto)
  }

  async delete(id: string) {
    return await this.httpService.delete<boolean>(`/admin/package-banner/${id}`)
  }

  async setEnabled(id: string, enabled: boolean) {
    if (enabled) {
      return await this.httpService.patch<Boolean>(`/admin/package-banner/${id}/enable`)
    }

    return await this.httpService.patch<Boolean>(`/admin/package-banner/${id}/disable`)
  }
}