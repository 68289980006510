import { Injectable } from "@angular/core"

import { ExportInquiryDto, ExportInquiryResultDto, FindInquiryDto, InquiryDetailDto, InquiryDto, PageResultDto } from "../dto"
import { HttpService } from "./http.service"


@Injectable()
export class InquiryService {
  constructor(
    private httpService: HttpService) {
  }

  async find({ limit, offset, searchText }: FindInquiryDto) {
    return await this.httpService.get<PageResultDto<InquiryDto>>('/admin/inquiry', { limit, offset, searchText })
  }

  async findOne(id: string) {
    return await this.httpService.get<InquiryDetailDto>(`/admin/inquiry/${id}`)
  }

  async exportExcel(dateFilterDto: ExportInquiryDto) {
    return await this.httpService.get<ExportInquiryResultDto>(`/admin/inquiry/export`, dateFilterDto)
  }
}