import { Injectable } from "@angular/core";
import { HttpService } from "./http.service";
import { CreatePackagePlanDto, PackagePlanDetailDto, PackagePlanDto, UpdatePackagePlanDto } from "@api/dto";

@Injectable()
export class PackagePlanService {
  constructor(
    private httpService: HttpService) {
  }

  async updateSort(packagePlanIds: string[]) {
    return await this.httpService.put<boolean>(`/admin/package-plan/sort`, { packagePlanIds })
  }

  async create(id: string, createPackagePlanDto: CreatePackagePlanDto) {
    return await this.httpService.post<PackagePlanDto>(`/admin/package-plan/${id}`, createPackagePlanDto)
  }

  async findOne(id: string) {
    return await this.httpService.get<PackagePlanDetailDto>(`/admin/package-plan/${id}`)
  }

  async update(id: string, updatePackagePlanDto: UpdatePackagePlanDto) {
    return await this.httpService.put<boolean>(`/admin/package-plan/${id}`, updatePackagePlanDto)
  }

  async delete(id: string) {
    return await this.httpService.delete<boolean>(`/admin/package-plan/${id}`)
  }

  async setEnabled(id: string, enabled: boolean) {
    if (enabled) {
      return await this.httpService.patch<Boolean>(`/admin/package-plan/${id}/enable`)
    }

    return await this.httpService.patch<Boolean>(`/admin/package-plan/${id}/disable`)
  }
}