import { HttpHeaders } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { HTTP_HEADER_BRANCH_KEY } from "../core"

import { HttpService } from "./http.service"
import { CreateServiceDto, ServiceDetailDto, UpdateServiceDto, FindPackageDto, ServiceDto } from "@api/dto/package.dto"
import { PageResultDto } from "@api/dto"


@Injectable()
export class ServiceService {
  constructor(
    private httpService: HttpService) {
  }

  async find({ limit, offset, searchText }: FindPackageDto) {
    return await this.httpService.get<PageResultDto<ServiceDto>>('/admin/service', { limit, offset, searchText })
  }

  async updateSortService(serviceIds: string[]) {
    return await this.httpService.put<boolean>(`/admin/service/sort`, { serviceIds })
  }

  async findOne(id: string) {
    return await this.httpService.get<ServiceDetailDto>(`/admin/service/${id}`)
  }

  async create(createPackageDto: CreateServiceDto) {
    return await this.httpService.post<ServiceDto>('/admin/service', createPackageDto)
  }

  async update(id: string, updateServiceDto: UpdateServiceDto) {
    return await this.httpService.put<boolean>(`/admin/service/${id}`, updateServiceDto)
  }

  async delete(id: string) {
    return await this.httpService.delete<boolean>(`/admin/service/${id}`)
  }

  async setEnabled(id: string, enabled: boolean) {
    if (enabled) {
      return await this.httpService.patch<Boolean>(`/admin/service/${id}/enable`)
    }

    return await this.httpService.patch<Boolean>(`/admin/service/${id}/disable`)
  }
}