import { Injectable } from "@angular/core";
import { HttpService } from "./http.service";
import { CreatePackageDto, PackageDetailDto, PackageDto, UpdatePackageDto } from "@api/dto";

@Injectable()
export class PackageService {
  constructor(
    private httpService: HttpService) {
  }

  async updateSort(packageIds: string[]) {
    return await this.httpService.put<boolean>(`/admin/package/sort`, { packageIds })
  }

  async create(id: string, createPackageDto: CreatePackageDto) {
    return await this.httpService.post<PackageDto>(`/admin/package/${id}`, createPackageDto)
  }

  async findOne(id: string) {
    return await this.httpService.get<PackageDetailDto>(`/admin/package/${id}`)
  }

  async update(id: string, updatePackageDto: UpdatePackageDto) {
    return await this.httpService.put<boolean>(`/admin/package/${id}`, updatePackageDto)
  }

  async delete(id: string) {
    return await this.httpService.delete<boolean>(`/admin/package/${id}`)
  }

  async setEnabled(id: string, enabled: boolean) {
    if (enabled) {
      return await this.httpService.patch<Boolean>(`/admin/package/${id}/enable`)
    }

    return await this.httpService.patch<Boolean>(`/admin/package/${id}/disable`)
  }
}